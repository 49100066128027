import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Head from 'next/head';
import Style from './BannerWithPara.scss';
import Image from '../CloudinaryImage/CloudinaryImage';
import useScreenSize from '../Hooks/useScreenSize';
import { getImageUrl } from '../../adapters/cloudinary.adapter';
import getScreenSize from '../ReusableComponents/useScreenSize';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';
import LazyLoad from 'react-lazyload';

const BannerWithPara = (props) => {
    const { paraContent, extraLine, bannerImage, title, styles } = props;
    let resolution = useScreenSize();

    if (!resolution) {
        resolution = { isMobile: true };
    }

    const image = bannerImage !== null && (resolution.isMobile ? bannerImage.imageSmartphone : bannerImage.imageDesktop);
    const { isDesktop,isMobile } = getScreenSize()
    return (
        <div className={classNames(styles, 'main-container')}>
            <Head>
                {
                    <>
                    {isMobile && <link rel="preload" href={buildContentfullImageUrl(getImageUrl(image?.asset?.url, { 'width': 480 }))} as="image" media="(max-width: 360px)" />}
                    {isDesktop && <link rel="preload" href={buildContentfullImageUrl(getImageUrl(image?.asset?.url, { 'width': 1920 }))} as="image" media="(min-width: 1024)" />}
                    </>
                }
            </Head>
            { (title || extraLine) && (
                <div className="container">
                    {title && (
                        <div className="profile-title">
                            <h2
                                className="title"
                                dangerouslySetInnerHTML={{
                                    __html: title }}
                            />

                        </div>
                    )}
                    {extraLine && (
                        <p
                            className="extra-line"
                            dangerouslySetInnerHTML={{
                                __html: extraLine,
                            }}
                        />
                    )}
                </div>
            )}
            {image && (
                <div className="banner-wrap">
                    <LazyLoad>
                        <Image image={image} />
                    </LazyLoad>
                </div>
            )}
            { paraContent
                && (
                    <div className="container">
                        <p
                            className="content-para"
                            dangerouslySetInnerHTML={{
                                __html: paraContent,
                            }}
                        />
                    </div>
                )}
            <style jsx>{Style}</style>
        </div>
    );
};

BannerWithPara.propTypes = {
    paraContent: PropTypes.object,
    bannerImage: PropTypes.object,
    extraLine: PropTypes.object,
    title: PropTypes.any,
    styles: PropTypes.any,
};

export default BannerWithPara;
