import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import isPinkCategory from '../../helpers/category-color';
import BazaarVoice from '../../adapters/bazaarVoice.adapter';

import SearchIcon from '../UI/SearchInput/SearchIcon';
import ProductReviews from '../ProductReviews/ProductReviews';
import BuyNowButton from '../BuyNowButton/BuyNowButton';
import Image from '../CloudinaryImage/CloudinaryImage';

import style from './ProductDetails.scss';
import globalStylePink from '../../../styles/bg-global-pink.scss';
import globalStylePurple from '../../../styles/bg-global-purple.scss';
import { getImageUrl } from '../../adapters/cloudinary.adapter';
import getScreenSize from '../ReusableComponents/useScreenSize';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';
import LazyLoad from 'react-lazyload';

const ProductDetails = (props) => {
    const { name, nameEn, imagesCollection, description, description1, gtin, ratingsReviewsId } = props.product;
    const { image, imageThumbnail } = imagesCollection.items[0];
    const [isEnlarged, setEnlarged] = useState(false);
    const globalStyle = isPinkCategory(nameEn) ? globalStylePink : globalStylePurple;

    const [ratingStats, setRatingStats] = useState({ AverageOverallRating: 0, TotalReviewCount: 0 });
    const rnrId = ratingsReviewsId;

    useEffect(() => {
        BazaarVoice.getRating(rnrId).then((result) => {
            if (result.Results.length > 0) {
                const { AverageOverallRating, TotalReviewCount } = result.Includes.Products[rnrId].ReviewStatistics;
                setRatingStats({ AverageOverallRating, TotalReviewCount });
            }
        });
    }, []);

    const enlarge = () => {
        setEnlarged(!isEnlarged);
    };

    const { TotalReviewCount, AverageOverallRating } = ratingStats;
    const reviewLink = `https://www.myrepi.com/brands/whisper/${ratingsReviewsId}`.slice(0, -9);
    const { isDesktop,isMobile } = getScreenSize()

    return (
        <div className="product-details container">
            <Head>
                <>
                    {isMobile && <link rel="preload" href={buildContentfullImageUrl(getImageUrl(imageThumbnail.asset.url, { 'width': 480 }))} as="image" media="(max-width: 360px)" />}
                    {isDesktop && <link rel="preload" href={buildContentfullImageUrl(getImageUrl(imageThumbnail.asset.url, { 'width': 1920 }))} as="image" media="(min-width: 1024px)" />}
                </>
            </Head>
            <div className="row">
                <div className="col-md-5 col-product-images">
                    <LazyLoad>
                        <Image image={imageThumbnail} className="thumb" />
                    </LazyLoad>
                    <div className="magnifier">
                        <SearchIcon color="#8347ad" onClick={enlarge} />
                    </div>
                    {isEnlarged && (
                        <div className="enlarged">
                            <LazyLoad>
                                <Image image={image} />
                            </LazyLoad>
                            <div className="close-enlarged" onClick={enlarge} />
                        </div>
                    )}
                </div>
                <div className="col-md-7 col-product-data">
                    <h1 className="product-name">{name}</h1>
                    {TotalReviewCount > 0
                        && (
                            <ProductReviews
                                reviewLink={reviewLink}
                                averageRating={AverageOverallRating}
                                totalReviews={TotalReviewCount}
                            />
                        )}
                    <div className="description">
                        <p>
                            {description}<br />
                            {description1}
                        </p>
                    </div>
                    <BuyNowButton sku={gtin} />
                </div>
            </div>
            <style jsx>{style}</style>
            <style jsx>{globalStyle}</style>
        </div>
    );
};

ProductDetails.propTypes = {
    product: PropTypes.object,
};

export default ProductDetails;
