import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { GtmBody, SeoSchema, Canonical } from './Scripts';

import Header from './Header/Header';
import Footer from './Footer/Footer';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
// import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import Style from './Layout.scss';

const Layout = ({ children, header, footer, pageInfo = {}, tsuruhaPage, pageType }) => {
    const { title, description, canonicals, ogType, url, ogTitle, ogDescription, ogImage, title1, keywords } = pageInfo || {};
    const headerNavigation = header && header.navigation.items[0].navigationColumn.items;
    const loginColumn = header && header.navigation.items[0].loginColumnCollection.items[0];
    const { NODE_ENV } = process.env;
    return (
        <>
            <Head>
                <title>{title}</title>
                {title1 && <meta name="title" content={title1} />}
                {keywords && <meta name="keywords" content={keywords} />}
                <meta name="description" content={description} />
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                <meta property="og:type" content={ogType} />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={ogTitle} />
                <meta property="og:description" content={ogDescription} />
                <meta property="og:image" content={ogImage && ogImage.url} />
                <meta name="theme-color" content="#8347ad" />

                <link rel="icon" type="image/x-icon" href="/static/images/favicon.ico" />
                <link rel="apple-touch-icon" sizes="76x76" href="/static/images/favicon-76x76.png" />
                <link rel="apple-touch-icon" sizes="120x120" href="/static/images/favicon-120x120.png" />
                <link rel="apple-touch-icon" sizes="152x152" href="/static/images/favicon-152x152.png" />
                <link rel="apple-touch-icon" sizes="192x192" href="/static/images/favicon-192x192.png" />
                <link rel="apple-touch-icon" sizes="512x512" href="/static/images/favicon-512x512.png" />
                <link rel="mask-icon" href="/static/images/safari-pinned-tab.svg" color="#592C82" />
                {NODE_ENV === 'development' ? <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap-grid.min.css" /> : <link rel="stylesheet" href="/styles/bootstrap-grid.min.css" />}
                {NODE_ENV === 'development' ? <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" /> : <link rel="stylesheet" href="/styles/bootstrap.min.css" />}
                <link rel="manifest" href="/static/manifest.json" />
                {/* <Canonical links={canonicals && canonicals?.items} /> */}
                <link rel="canonical" href={canonicals && canonicals.items[0] && canonicals.items[0].canonical} />
                <SeoSchema jsonLdCollection={[]} />
            </Head>
            <GtmBody />
            <div className="main-container">
                {header && <Header navigation={headerNavigation} retailers={header.retailers} pageInfo={pageInfo} loginColumn={loginColumn} />}
                {header && pageType && pageType[0] && pageType[0]['__typename'] !== 'ArticleList' && <Breadcrumbs pageInfo={pageInfo} tsuruhaPage={tsuruhaPage} />}
                {children}
                {footer && <Footer navigation={footer.navigation} retailers={footer.retailers} products={footer.products} />}
            </div>

            <style jsx global>
                {Style}
            </style>
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.element,
    footer: PropTypes.object,
    header: PropTypes.object,
    pageInfo: PropTypes.object,
    tsuruhaPage: PropTypes.bool,
    pageType: PropTypes.array,
};

export default Layout;
