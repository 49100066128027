/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';

import isPinkCategory from '../../../helpers/category-color';
import ProductReviews from '../../ProductReviews/ProductReviews';
import Image from '../../CloudinaryImage/CloudinaryImage';

import Style from '../ProductList.scss';
import { classNameForGA, dataActionForGA } from '../../../helpers/dataForGA';
import LazyLoad from 'react-lazyload';

const Products = ({ categories }) => (
    <div className="react-or-nextjs-bug-fix">
        {categories.map((category, i) => (
            <div id={`categoryContainer${i + 1}`} className="category-container" key={i}>
                <div className="products-list-subtitle">
                    <h3>{category.name}</h3>
                </div>
                <ul className="category-list clearfix">
                    {category.products.map((product, x) => {
                        const className = `item product ${isPinkCategory(category.nameEn) ? 'pink' : 'purple'}`;
                        const productName = product.name.replace(category.name, '');
                        const reviewLink = `https://www.myrepi.com/brands/whisper/${product.rnrId}`.slice(0, -9);

                        return (
                            <li className={className} key={x}>
                                <a className={classNameForGA(product.url)} {...dataActionForGA(product.url)} href={product.url}>
                                    <div className="image">
                                        <LazyLoad>
                                            <Image image={product.image} />
                                        </LazyLoad>
                                    </div>
                                </a>
                                <div className="info">
                                    <a className={classNameForGA(product.url)} {...dataActionForGA(product.url)} href={product.url}>
                                        <h4>{productName}</h4>
                                    </a>
                                    <ProductReviews
                                        averageRating={product.averageOveralRating}
                                        totalReviews={product.totalReviewCount}
                                        reviewLink={reviewLink}
                                    />
                                </div>
                            </li>
                        );
                    })}
                </ul>
                <style jsx>{Style}</style>
            </div>
        ))}
    </div>
);

Products.propTypes = {
    categories: PropTypes.array,
};

export default Products;
