import React, { Component } from 'react';
import Router from 'next/router';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import { Dialog, DialogTitle } from '../Dialog';
import style from './userCommentForm.scss';
import { storageSet, GetEmailVerified } from '../../helpers/StorageEvents';

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nickName: '',
            age: '',
            post: '',
            loader: false,
            errors: {
                nickName: '',
                age: '',
                post: '',
            },
            errorBoolean: true,
            isLoggedIn: '',
            postStatus: '',
        };
    }

    onChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.ValidateFields(name, value);
    }

    ValidateFields = (name, value) => {
        const { errors } = this.state;
        const limit = 256;
        const nameLimit = 8;
        switch (name) {
        case 'nickName':
            errors.nickName = value ? '' : 'please enter value';
            if (value.length <= nameLimit) {
                this.setState(
                    { [name]: value },
                );
            }
            break;

        case 'age':
            errors.age = value ? '' : 'please enter value';
            this.setState(
                { [name]: value },
            );
            break;
        case 'post':
            errors.post = value ? '' : 'please enter value';
            if (value.length <= limit) {
                this.setState(
                    { [name]: value },
                );
            }
            break;

        default:
            break;
        }
        const { nickName, post, age } = this.state.errors;
        if (!nickName && !post && !age && this.state.age && this.state.post && this.state.nickName) {
            this.setState({
                errorBoolean: false,
            });
        } else {
            this.setState({
                errorBoolean: true,
            });
        }
    }

    componentDidMount = () => {
        if (window.sessionStorage.isUserLoggedIn && GetEmailVerified()) {
            this.setState({ isLoggedIn: window.sessionStorage.isUserLoggedIn });
        }
    }

    handleSetTimeout = () => {
        setTimeout(() => {
            this.setState({
                postStatus: '',
            });
        }, 12000);
    }

    handleOnChangeEvent = () => {
        document.getElementById('postComments').scrollIntoView({
            behavior: 'smooth',
        });
    };

    handleGAEvents = async (eventCate, eventAction, eventLabel) => {
        await window.dataLayer.push({
            'event': 'customEvent',
            'GAeventCategory': eventCate,
            'GAeventAction': eventAction,
            'GAeventLabel': eventLabel,
            'GAeventValue': 0,
            'GAeventNonInteraction': false,
        });
    }

    handleOnClickGAEvent = () => {
        if (this.handleGAEvents('event_ratings_action', 'event_view_exmoviefeedback', 'whisper_view_feedback')) {
            storageSet('videoFeedbackVia_incontinence', true);
            storageSet('videoFeedbackVia_pelvic', false);
            Router.push('/urinary-incontinence-message-post');
        }
    }

    onSubmit = async () => {
        this.setState({ errorBoolean: true, loader: true });

        if (this.props.descStatus) {
            this.handleGAEvents('event_ratings_action',
                'event_submit_movie_review',
                'whisper_submit_review_exmovie');
        } else {
            this.handleGAEvents('event_ratings_action',
                'event_submit_movie_review',
                'whisper_submit_review_exmovie_feedbackpage');
        }

        const { nickName, age, post } = this.state;
        const data = {
            nickName,
            age,
            post,
        };
        this.handleSetTimeout();
        this.setState({
            postStatus: '投稿を受け付けました ',
            errorBoolean: true,
            nickName: '',
            age: '',
            post: '',
            loader: false,
        });

    }

    render() {
        const { className, descStatus, isMobile } = this.props;
        return (
            <div className={className}>
                {descStatus && (
                    <div className="HeadingContainer">
                        {isMobile
                            ? <div className="Heading"> みんなの「ウィスパー体操」の感想やUIの体験談を見る</div>
                            : <div className="Heading"> みんなの「ウィスパー体操」の感想やUIの体験談を見る▶ </div>}
                        <button
                            type="button"
                            className="Button"
                            onClick={this.handleOnClickGAEvent}
                        >
                            確認する
                        </button>
                    </div>
                )}
                {this.state.loader ? (
                    <Dialog open={true}>
                        <div className="">
                            <DialogTitle className="Loader-overlay" component="div">
                                <Loader
                                    type="TailSpin"
                                    color="#8347ad"
                                    className="loader"
                                    height={100}
                                    width={100}
                                    visible={true}
                                />
                            </DialogTitle>
                        </div>
                    </Dialog>
                )
                    : (
                        <div className="contentConatiner" id="postComments">
                            {!this.state.isLoggedIn && <div className="LoginAlert">{(isMobile || !descStatus) ? <span>ログイン後、<br /> 投稿してください。</span> : <span>ログイン後、投稿してください。</span>}</div>}
                            <div className={this.state.isLoggedIn ? 'visible' : 'transparent'}>
                                {descStatus && (
                                    <p className="Description">
                                        「ウィスパー体操」の感想や <br />
                                        UI(尿もれ)についてみなさんの対策や <br />
                                        体験談をシェアしてください。
                                    </p>
                                )}
                                <div className="formFlex">
                                    <div className="nameLabel">
                                        <label>ニックネーム（８文字以内）</label>
                                        <input
                                            className="input_field"
                                            type="text"
                                            placeholder="oooooooo"
                                            value={this.state.nickName}
                                            name="nickName"
                                            onChange={this.onChange}
                                            onBlur={this.onChange}
                                        />
                                    </div>
                                    <div className="ageLabel">
                                        <label>年代</label>
                                        <select
                                            aria-label='age'
                                            className="age_dropdown"
                                            name="age"
                                            onBlur={this.onChange}
                                        >
                                            <option />
                                            <option value="20 - 29">20~29歳</option>
                                            <option value="30 -39">30~39歳</option>
                                            <option value="40 - 49">40~49歳</option>
                                            <option value="50 - 59">50~59歳</option>
                                            <option value="60 - 69">60~69歳 </option>
                                            <option value="70 +">70歳~</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="commentsLabel">
                                    <label>投稿文</label>
                                    <textarea
                                        aria-label='post'
                                        className="Textbox"
                                        type="text"
                                        value={this.state.post}
                                        name="post"
                                        onChange={this.onChange}
                                        onBlur={this.onChange}
                                    />
                                </div>
                            </div>
                            <div className="submitSection">
                                <button
                                    type="button"
                                    disabled={this.state.errorBoolean}
                                    onClick={this.onSubmit}
                                >投稿する
                                </button>
                                <div className="conditionApply">
                                    <p>※個人を特定する書き込みはお控えください。</p>
                                    <p>※誹謗中傷など不適切な投稿はお控えください。</p>
                                    <p>※上記に該当するコメントは予告なく削除させていただきます。</p>
                                </div>
                            </div>
                            <div className="postStatus"><p>{this.state.postStatus}</p></div>
                        </div>
                    )}
                {isMobile
                    && (
                        <div className="footerfix" onClick={this.handleOnChangeEvent}>
                            {/* <span className="footersection">投稿する</span> */}
                        </div>
                    )}
                <style jsx> {style} </style>
            </div>
        );
    }
}

index.propTypes = {
    className: PropTypes.any,
    descStatus: PropTypes.any,
    isMobile: PropTypes.any,
};
