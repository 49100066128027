import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Proptypes from "prop-types";
import ProductReviews from "../ProductReviews/ProductReviews";
import BazaarVoice from "../../adapters/bazaarVoice.adapter";
import Image from "../CloudinaryImage/CloudinaryImage";
import { dataActionForGA } from "../../helpers/dataForGA";
import { isAbsorbencyPages } from "../../helpers/uiHelper";
import BuyNowButton from "../BuyNowButton/BuyNowButton";
import Styles from "../ProductCard/ProductCard.scss";
import { buildContentfullImageUrl } from "../ReusableComponents/imageHelper";
import LazyLoad from "react-lazyload";

const ProductCard = ({ product, resolution }) => {
  const [ratingStats, setRatingStats] = useState({
    AverageOverallRating: 0,
    TotalReviewCount: 0,
  });
  const router = useRouter();
  useEffect(() => {
    BazaarVoice.getRating(product.rnrId).then((result) => {
      if (result.Results.length > 0) {
        const { AverageOverallRating, TotalReviewCount } =
          result.Includes.Products[product.rnrId].ReviewStatistics;
        setRatingStats({ AverageOverallRating, TotalReviewCount });
      }
    });
  }, []);

  if (!resolution) {
    return null;
  }

  const { TotalReviewCount, AverageOverallRating } = ratingStats;

  const image = resolution.isMobile ? product.imageSp : product.imageDt;
  const reviewLink = `https://www.myrepi.com/brands/whisper/${product.urlId}`;

  const premiumCategories = [
    "うすさらエアリー",
    "うすさらエアリー+",
    "うすさらシルキー",
  ];
  const premiumProduct =
    premiumCategories.indexOf(product?.productCategory) >= 0;
  return (
    <div
      className={
        premiumProduct
          ? "productsLineUp-card premium-product"
          : "productsLineUp-card basic-product"
      }
    >
      {isAbsorbencyPages(router.asPath) && (
        <h3 className="productsLineUp-head">{product?.productCategory}</h3>
      )}
      <div className="product-card-image">
        <LazyLoad>
          <Image image={image} />
        </LazyLoad>
      </div>
      <h4>{product.title}</h4>
      <div className="productsLineUp-card-text">
        <div className="secnt-size">
          {product.scent && <p className={product.scent?.includes('さわやかな香り')?'refreshing-scent':'unscented'}>{product.scent}</p>}
          <img
            alt={product.productVariant.description}
            src={buildContentfullImageUrl(product.productVariant.url)}
          />
        </div>
        <div className="pack-size-container">
          {product.packSizes
            ?.split("|")
            ?.filter(Boolean)
            ?.map((pack, ind) => {
              if ( ind >= 1) {
                return (
                  <>
                    <span className="pack-bar">|</span>
                    <span className="pack-size">{pack}</span>
                  </>
                );
              }
              return <span className="pack-size">{pack}</span>;
            })}
        </div>
        {/* <ProductReviews
                    reviewLink={reviewLink}
                    averageRating={AverageOverallRating}
                    totalReviews={TotalReviewCount}
                /> */}
        <div className="bin-button" {...dataActionForGA(product.gtin)}>
          <BuyNowButton sku={product.gtin} />
        </div>
      </div>
      <style jsx>{Styles}</style>
    </div>
  );
};

ProductCard.propTypes = {
  product: Proptypes.object,
  resolution: Proptypes.object,
};
export default ProductCard;
