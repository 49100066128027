/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';
import { buildFooterLinks } from './Footer.helper';

import FooterProducts from '../FooterProducts/FooterProducts';

import Style from './Footer.scss';
import { classNameForGA, dataActionForGA } from '../../helpers/dataForGA';
import { generatePrivacyLinkV2 } from '@mw/privacy-link';
import { privacyLinkText } from '../../helpers/constants';
import Lazyload from 'react-lazyload'


const Footer = ({ navigation, retailers, products }) => {
    const currentYear = new Date().getFullYear();

    const footerLinks = buildFooterLinks(navigation);
    const [privacyLink, setPrivacyLink] = useState('')
    useEffect(() => {
        const fetchData = async () =>
            await generatePrivacyLinkV2({
                lang: process.env.SITE_LANG,
                countryCode: process.env.SITE_COUNTRY,
                linkType: privacyLinkText,
            })

        fetchData()
            .then((privacyLink) => {
                setPrivacyLink(privacyLink.link)
            })
            .catch(console.error)
    }, [])

    return (
        <Lazyload offset={20}>
            <div className="full-width-mobile footer">
                <div className="container">
                    <ul className="footer-list">
                        {footerLinks.map((link) => {
                            const className = includes(link.title, 'AdChoices') ? 'ad-choices' : '';
                            const target = link.newWin ? '_blank' : '_self';
                            const rel = link.newWin ? 'noopener noreferrer' : '';
                            const reusablePrivacy = link.title === 'プライバシー' ? privacyLink : link.url;

                            return (
                                <li key={link.title}>
                                    <a
                                        href={reusablePrivacy}
                                        className={`${className} ${classNameForGA(link.url)}`}
                                        {...dataActionForGA(link.url)}
                                        target={target}
                                        rel={rel}
                                    >
                                        {link.title}
                                    </a>
                                </li>
                            );
                        })}
                        <li className="copy">&copy; {currentYear} Procter &amp; Gamble</li>
                    </ul>
                </div>
                <style jsx>{Style}</style>

            </div>
        </Lazyload>
    );
};

Footer.propTypes = {
    navigation: PropTypes.object,
    products: PropTypes.object,
    retailers: PropTypes.object,
};

export default Footer;
