import React from "react";
import PropTypes from "prop-types";
import useIsInViewport from "use-is-in-viewport";
import { useRouter } from "next/router";
import useScreenSize from "../Hooks/useScreenSize";
import Image from "../CloudinaryImage/CloudinaryImage";
import Style from "./PromoBanner.scss";
import { isProductPages } from "../../helpers/uiHelper";
import LazyLoad from "react-lazyload";

const PromoBanner = ({
  heading,
  imageDt,
  imageSp,
  link,
  externalLink,
  customClass,
  jumpLinkId,
}) => {
  const resolution = useScreenSize();
  const [isInViewport, targetRef] = useIsInViewport();
  const isHomePage =
    typeof window !== "undefined" && window.location.pathname === "/";
  const router = useRouter();
  if (!resolution) {
    return null;
  }
  const image = resolution.isMobile ? imageSp : imageDt;

  const onBannerClick = () => {
    const url = externalLink || (link && link.seoHead ? link.seoHead.url : "");
    if (typeof window !== "undefined" && url) {
      window.location = url;
    }
  };

  return isHomePage || isProductPages(router.asPath) ? (
    <div
      ref={targetRef}
      className={`${
        isInViewport
          ? "promo-banner event_banner_click Homepage-promo-banner hm-animate"
          : "promo-banner event_banner_click hm-hide"
      } ${customClass ?? ""}`}
      id={jumpLinkId??''}
    >
      {heading && <div className="heading">{heading}</div>}
      <div
        className="promo-banner-wrapper"
        data-action-detail={link && link.seoHead.url}
        onClick={onBannerClick}
      >
        <LazyLoad>
          <Image image={image} loading="lazy" />
        </LazyLoad>
      </div>
      <style jsx>{Style}</style>
    </div>
  ) : (
    <div
      className="promo-banner event_banner_click"
      data-action-detail={link && link.seoHead.url}
      onClick={onBannerClick}
    >
      <LazyLoad>
        <Image image={image} />
      </LazyLoad>
      <style jsx>{Style}</style>
    </div>
  );
};

PromoBanner.propTypes = {
  heading: PropTypes.string,
  imageDt: PropTypes.object,
  imageSp: PropTypes.object,
  link: PropTypes.object,
  externalLink: PropTypes.string,
  customClass: PropTypes.string,
  jumpLinkId: PropTypes.string,
};

export default PromoBanner;
