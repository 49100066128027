import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import Style from './Video.scss';
import { storageSet } from '../../helpers/StorageEvents';
import { isArticleVideoPage } from '../../helpers/uiHelper';
import LazyLoad from 'react-lazyload';

const handleGAEvents = async () => {
    await window.dataLayer.push({
        'event': 'customEvent',
        'GAeventCategory': 'event_informational_action',
        'GAeventAction': 'event_view_movie_page',
        'GAeventLabel': 'whisper_movie_pageview',
        'GAeventValue': 0,
        'GAeventNonInteraction': false,
    });
    return true;
};

const Videofeedback = () => {
    if (handleGAEvents()) {
        storageSet('videoFeedbackVia_incontinence', false);
        storageSet('videoFeedbackVia_pelvic', true);
        Router.push('/pelvic-training-exercise-video');
    }
};

const Video = (props) => {
    const { styles, videoLink, video,autoplay } = (props.videoData) ? props.videoData : props;

    const [iframeStatus, setIframeStatus] = useState(false);
    console.log(video, 'props videooooo');
    useEffect(() => {
        setIframeStatus(true);
    });

    return (
        <>
            {video?.url && <div className="video-youtube-part">
                <div className={styles || ''}>
                    {iframeStatus
                        && (
                            <LazyLoad>
                                <video width="600" height="338" controls autoPlay allowFullScreen>
                                        <source src={video && video.url ? video.url : videoLink} type="video/mp4" />
                                </video>
                            </LazyLoad>
                        )}
                </div>
            </div>}
            {isArticleVideoPage ? '' : <button type="button" className="movieAdditionBtn" onClick={Videofeedback}> {'「ウィスパー体操」レッスン動画を見る '}</button>}
            <style jsx>{Style}</style>
            <LazyLoad className='lazyLoadName'>
                <iframe src={videoLink+'?autoplay=1&mute=1&loop=1&playlist='+videoLink?.split('/').pop()} className={styles??''}  allow="fullscreen;"></iframe>
            </LazyLoad>
        </>
    );
};

Video.propTypes = {
    videoData: PropTypes.object,
};

export default Video;
