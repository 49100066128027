import React from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';
import Image from '../CloudinaryImage/CloudinaryImage';

import Style from './ProductFeature.scss';
import LazyLoad from 'react-lazyload';

const HIGHLIGHT_WORD = 'みて';
const SPLIT_CHAR = '+';

const ProductFeature = (props) => {
    const { productFeatures } = props;

    const highlightTitle = (text) => {
        if (text.substr(0, HIGHLIGHT_WORD.length) === HIGHLIGHT_WORD) {
            return (
                <>
                    <span className="highlight">{HIGHLIGHT_WORD}</span>{text.substr(HIGHLIGHT_WORD.length)}
                    <style jsx>{Style}</style>
                </>
            );
        }
        return text;
    };

    const featureTitle = (fTitle) => {
        if (!includes(fTitle, SPLIT_CHAR)) return highlightTitle(fTitle);

        const [part1, part2] = fTitle.split(SPLIT_CHAR);
        return (
            <>
                {highlightTitle(part1)}
                <br />
                {part2}
                <style jsx>{Style}</style>
            </>
        );
    };

    const featureNote = (note) => {
        if (!includes(note, SPLIT_CHAR)) return note;

        const parts = note.split(SPLIT_CHAR);
        return (
            <>
                {parts.map((part, i) => <span className="note-part" key={i}>{part}</span>)}
                <style jsx>{Style}</style>
            </>
        );
    };

    return (
        <div className="product-feature">
            {productFeatures.items.map((feature, key) => {
                const { title, description1, description2, image, note } = feature;

                return (
                    <div key={key} className="feature">
                        <div className="info">
                            <h2>{featureTitle(title)}</h2>
                            <div className="description-sizer">
                                {description1 && <h3>{description1}</h3>}
                                {description2 && <h3>{description2}</h3>}
                            </div>
                            {note && <p className="note">{featureNote(note)}</p>}
                        </div>
                        <div className="image">
                            <LazyLoad>
                                <Image image={image} />
                            </LazyLoad>
                        </div>
                    </div>

                );
            })}
            <style jsx>{Style}</style>
        </div>
    );
};

ProductFeature.propTypes = {
    productFeatures: PropTypes.object,
};

export default ProductFeature;
