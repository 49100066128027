/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Image from '../CloudinaryImage/CloudinaryImage';
import Style from './InterviewList.scss';
import { classNameForGA, dataActionForGA } from '../../helpers/dataForGA';
import LazyLoad from 'react-lazyload';

const InterviewList = ({ title, subtitle, interviewsCollection }) => {
    const interviews = interviewsCollection.items;

    return (
        <div className="container">
            <div className="interview-list">
                <h3>{title}</h3>
                <h2>{subtitle}</h2>
                <div className="row">
                    {interviews.map((interview, i) => {
                        const { url } = interview.page.seoHead;
                        const image = interview.featuredImageNumber;
                        return (
                            <div className="col-md-6 interview" key={i}>
                                <a className={classNameForGA(url)} {...dataActionForGA(url)} href={url} key={i}>
                                    <LazyLoad>
                                        <Image image={image} />
                                    </LazyLoad>
                                    <h4 className={classNameForGA(url)} {...dataActionForGA(url)}>{interview.heading}</h4>
                                    <h5>{interview.heading1} <br /> {interview.heading2}</h5>
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
            <style jsx>{Style}</style>
        </div>
    );
};

InterviewList.propTypes = {
    interviewsCollection: PropTypes.object,
    title: PropTypes.string,
    subtitle: PropTypes.string,
};

export default InterviewList;
