import React from 'react';
import PropTypes from 'prop-types';
import useIsInViewport from 'use-is-in-viewport';
import useScreenSize from '../Hooks/useScreenSize';
import Style from './PromoBannerWithCta.scss';
import Image from '../CloudinaryImage/CloudinaryImage';
import { getImageUrl } from '../../adapters/cloudinary.adapter';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';
import LazyLoad from 'react-lazyload';

const PromoBannerWithCta = ({ imageDt, imageSp, button }) => {
    const resolution = useScreenSize();
    const isHomePage = typeof window !== 'undefined' && window.location.pathname === '/';
    const [isInViewport, targetRef] = useIsInViewport();

    if (!resolution) {
        return null;
    }
    const image = buildContentfullImageUrl(resolution.isMobile ? getImageUrl(imageSp.asset.url, {}) : getImageUrl(imageDt.asset.url, {}));

    const dynamicStyle = {
        backgroundImage: `url(${image})`,
    };

    const url = button.url ? button.url.seoHead.url : button.externalUrl;
    const target = button.target === '_blank' ? '_blank' : '_self';

    return isHomePage ? (
        <div ref={targetRef} className={isInViewport ? 'hm-animate' : 'hm-hide'}>
            <a href={url && !(url === '/')} className="homepage-banner-with-cta">
                <LazyLoad>
                    <Image image={resolution?.isMobile ? imageSp : imageDt} loading="lazy" />
                </LazyLoad>
                <div className="cta-button">
                    <a className="cta event_button_click" data-action-detail={url && !(url === '/')} href={url || '/'} target={target}>{button.text}</a>
                </div>
                <style jsx>{Style}</style>
            </a>
        </div>
    )
        : (
            <a href={url} className="promo-banner-with-cta" style={dynamicStyle}>
                <div>
                    <a className="cta event_button_click" data-action-detail={url} href={url} target={target}>{button.text}</a>
                </div>
                <style jsx>{Style}</style>
            </a>
        );
};

PromoBannerWithCta.propTypes = {
    button: PropTypes.object,
    imageDt: PropTypes.object,
    imageSp: PropTypes.object,
};

export default PromoBannerWithCta;
