import React from "react";


const GtmHead = (props) => {
    const { interval = 4 } = props
    const scriptString = `
    var timer=${interval}
    if (document.readyState !== "loading") {
        setTimeout(initGTM, timer*500);
    }
    else{
        document.addEventListener('DOMContentLoaded', function() {
            setTimeout(initGTM, timer*500);
        });
     }
      document.addEventListener('scroll', initGTMOnEvent);
      document.addEventListener('mousemove', initGTMOnEvent);
      document.addEventListener('touchstart', initGTMOnEvent);
      function initGTMOnEvent(event) {
        initGTM();
        event.currentTarget.removeEventListener(event.type, initGTMOnEvent); 
      }
    function initGTM() {
        if (window.gtmDidInit) {
            return false;
        }  
        console.info("Mounting GTM")
        window.gtmDidInit = true ;
        (function(w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            let f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != "dataLayer" ? "&l=" + l : "";
            j.setAttribute("defer", "");
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", "${process.env.GTM_ID??'GTM-N94XXFB'}");
    }
`
    return (
        <>
            <script
                id='gtm-container'
                strategy='afterInteractive'
                dangerouslySetInnerHTML={{
                    __html: scriptString,
                }}
            />
        </>
    )
 
};

export default GtmHead;
