/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import useScreenSize from '../Hooks/useScreenSize';

import BuyNowButton from '../BuyNowButton/BuyNowButton';
import Image from '../CloudinaryImage/CloudinaryImage';

import Style from './RecommendedProducts.scss';
import { classNameForGA, dataActionForGA } from '../../helpers/dataForGA';
import LazyLoad from 'react-lazyload';

const RecommendedProducts = ({ title, subtitle, productsCollection, gtin }) => {
    const resolution = useScreenSize();
    const products = productsCollection.items;

    if (!resolution) {
        return null;
    }

    return (
        <div className="full-width recommended-bg">

            <div className="buy-now-wrapper">
                <BuyNowButton sku={gtin} />
            </div>
            <div className="container">
                <div className="recommended-products">
                    <h3>{title}</h3>
                    <h4>{subtitle}</h4>
                    <div className="row">
                        {products.map((product, p) => {
                            const image = product.imagesCollection.items[0].imageThumbnail;
                            const { url } = product.page.seoHead;
                            const prodName = product.name.split(' ');
                            return (
                                <div className="col-6 col-md-3 recommended-product" key={p}>
                                    <a className={classNameForGA(url)} {...dataActionForGA(url)} href={url} key={p}>
                                        <LazyLoad>
                                            <Image image={image} />
                                        </LazyLoad>
                                        <h5 key={p}>{prodName[0]}<br />{prodName[1]}</h5>
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <style jsx>{Style}</style>
        </div>
    );
};

RecommendedProducts.propTypes = {
    gtin: PropTypes.string,
    productsCollection: PropTypes.object,
    subtitle: PropTypes.string,
    title: PropTypes.string,
};

export default RecommendedProducts;
