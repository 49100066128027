import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Style from './ProfileContent.scss';
import Image from '../CloudinaryImage/CloudinaryImage';
import useScreenSize from '../Hooks/useScreenSize';
import BannerWithContent from '../BannerWithPara/BannerWithPara';
import LazyLoad from 'react-lazyload';

const ProfileContent = (props) => {
    const resolution = useScreenSize();
    const { title, shortHead1, shortHead2, content, profileImage, extraline, akashiBannerCollection, BannerWithPara, link, styles } = props;

    if (!resolution) {
        return null;
    }
    console.log(BannerWithPara !== undefined ? BannerWithPara : 'not found');
    console.log(akashiBannerCollection.items);
    const image = profileImage !== null && (resolution.isMobile ? profileImage.imageSmartphone : profileImage.imageDesktop);

    const url = (link && link.seoHead) ? link.seoHead.url : '';

    return (
        <div className={classnames(styles, 'main-container')}>
            <div className="container clearfix">
                {title && (
                    <div className="profile-title">
                        <h2 className="title">{title}</h2>
                    </div>
                )}
                {
                    extraline && <p className="extraline">{extraline}</p>
                }
                {console.log(styles)}

                {(styles === 'second-section-mij' || styles === 'factory-manager factory-manager-profile')

                    ? (
                        <div className="profile-content-section clearfix">

                            <div className="Profile-copy-section">
                                {shortHead1 && <p className="head1">{shortHead1}</p>}
                                {shortHead2 && ((styles === 'second-section-mij' || styles === 'mij-second-profile') ? <h2 className="head2 title">{shortHead2}</h2> : <p className="head2">{shortHead2}</p>)}
                                <p
                                    className="content-para"
                                    dangerouslySetInnerHTML={{
                                        __html: content,
                                    }}
                                />
                            </div>

                            <div className="progile-image-section">
                                <LazyLoad>
                                    <Image image={image} />
                                </LazyLoad>
                                
                            </div>
                        </div>
                    )
                    : (styles === 'Akashi-Promo-Banner')
                        ? (
                            <div className="Akashi-promo-banner-section clearfix">
                                <div className="col-md-6 Akashi-promo-banner-container ">
                                    <a className="Akashi-promo-banner-CTA " data-action-detail={url} href={url}>
                                        <div className="Akashi-promo-image-section">
                                            <LazyLoad>
                                                <Image image={image} />
                                            </LazyLoad>
                                        </div>
                                        {
                                            resolution.isMobile
                                                ? (
                                                    <div className="Akashi-promo-content-section">
                                                        {shortHead2 && <p className="promo-head">{shortHead2}</p>}
                                                    </div>
                                                )
                                                : (
                                                    <div className="Akashi-promo-content-section">
                                                        {shortHead1 && <p className="promo-head">{shortHead1}</p>}
                                                    </div>
                                                )
                                        }

                                    </a>
                                </div>
                            </div>
                        )
                        : (
                            <div className="profile-content-section clearfix">
                                {shortHead2 && !shortHead1 && resolution.isMobile
                                && (
                                    <div className="Profile-copy-section">
                                        {((styles === 'mij-second-profile')
                                            ? <h2 className="head2 title">{shortHead2}</h2> : <p className="head2">{shortHead2}</p>
                                        )}
                                    </div>
                                )}

                                <div className="progile-image-section">
                                    <LazyLoad>
                                        <Image image={image} />
                                    </LazyLoad>
                                   
                                </div>
                                <div className="Profile-copy-section">
                                    {shortHead1 && <p className="head1">{shortHead1}</p>}

                                    {shortHead2 && !(resolution.isMobile && styles === 'mij-second-profile') && ((styles === 'second-section-mij' || styles === 'mij-second-profile')
                                        ? <h2 className="head2 title">{shortHead2}</h2> : <p className="head2">{shortHead2}</p>)}
                                    <p
                                        className="content-para"
                                        dangerouslySetInnerHTML={{
                                            __html: content,
                                        }}
                                    />
                                </div>
                            </div>
                        )}

            </div>

            { (akashiBannerCollection
                && akashiBannerCollection.items.length !== 0)
                && (
                    <BannerWithContent
                        bannerImage={akashiBannerCollection.items[0].bannerImage}
                        title={akashiBannerCollection.items[0].title}
                        extraLine={akashiBannerCollection.items[0].extraLine}
                        styles={akashiBannerCollection.items[0].styles}
                        paraContent={akashiBannerCollection.items[0].paraContent}
                    />
                )}
            <style jsx>{Style}</style>
        </div>
    );
};

ProfileContent.propTypes = {
    profileImage: PropTypes.object,
    title: PropTypes.object,
    shortHead1: PropTypes.object,
    shortHead2: PropTypes.object,
    content: PropTypes.object,
    link: PropTypes.object,
    extraline: PropTypes.object,
    akashiBannerCollection: PropTypes.object,
    BannerWithPara: PropTypes.object,
    styles: PropTypes.object,
};

export default ProfileContent;
