import css from 'styled-jsx/css';

const styles = css.global`/* @styled-jsx=global */

@import "./styles/global-variables";

.DrNomura-Article {
    p {
        color: $text-article;
        font-size: 21px;
        line-height: 42px;
        text-align: justify;
    }

    @media only screen and (max-width: $breakpoint-max) {
        margin-top: 0;

        p {
            margin-bottom: 18px;
            text-align: justify;
        }
    }
}

.ArticleSection {
    @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
        max-width: 100%;
    }

    @media only screen and (max-width: $breakpoint-tb-max) {
        margin-top: 15px;
        padding: 0 15px;
    }
    p {
        a {
            text-decoration: underline;
        }
    }
    :global(img) {
        width: 100%;
    }

    :global(sup) {
        font-size: 60%;
    }

    :global(span) {
        color: $text-article;
        display: block;
        font-size: 18px;
        text-align: right;
        width: 100%;
    }

    :global(.purple) {
        display: inline !important;
        color: #8347ad !important;
        font-size: 21px !important;
    }

    :global(a) {
        color: #8347ad !important;
        text-decoration: underline;
    }

    :global(.hyperlink) {
        display: inline !important;
        font-size: 22px !important;
        background: $purple-dark;
        border-radius: 5px;
        padding: 0 10px;
        color: #fff !important;

        // a {
        //     color: #fff !important;
        // }
    }

    :global(.DrNomura-part1-doubleimg) {
        width: 100%;
        position: relative;
        float: left;
        margin: 20px 0 40px;
        text-align: center;

        :global(.DrNomura-part1-doubleimg-left) {
            width: 60%;
            float: left;
            margin: 0 10px;
        }

        :global(.DrNomura-part1-doubleimg-right) {
            width: 35%;
            float: left;
            margin: 0 10px;
        }
    }

    .singleImage {
        margin: 50px auto;
    }

    .rightimage {
        float: right;
        clear: both;
        width: 50%;
    }

    .leftimage {
        float: left;
        clear: both;
        width: 50%;
    }

    .imgText1 {
        display: inline;
        font-size: 22px;

        &::after {
            content: ""; // non-breaking-space in content (Alt+0160)
            padding: 0 300px 0 0;
        }

        @media only screen and (min-device-width: 769px) and (max-device-width: 822px) {
            &::after {
                padding: 0 330px 0 0 !important;
            }
        }

        @media only screen and (min-device-width: 823px) and (max-device-width: 991px) {
            &::after {
                padding: 0 215px 0 0;
            }
        }
    }

    .imgText2 {
        display: inline;
        font-size: 22px;

        &+a {
            display: inline !important;
            font-size: 14px !important;
            background: #8347ad !important;
            border-radius: 12px;
            padding: 5px 10px;
            margin: 0px 10px;
            color: #fff !important;
        }
    }

    @media only screen and (max-width: $breakpoint-max) {
        :global(.DrNomura-part1-doubleimg) {
            margin: 20px auto 40px;

            :global(.DrNomura-part1-doubleimg-left) {
                width: 100%;
                float: left;
                margin: 0 auto 36px;

                :global(img) {
                    width: 100% !important;
                    margin: 0 auto !important;
                }
            }

            :global(.DrNomura-part1-doubleimg-right) {
                width: 100%;
                float: left;
                margin: 0 auto;

                :global(img) {
                    width: 100% !important;
                    margin: 0 auto !important;
                }
            }
        }

        .singleImage {
            margin: 36px auto 72px;
        }

        .rightimage,
        .leftimage {
            width: 100% !important;
            margin: 30px auto;
        }

        .imgText2 {
            font-size: 18px;

            &+a {
                display: block !important;
                margin: 20px auto;
                text-align: center;
            }
        }
    }

    :global(.H1 p) {
        color: #592c82 !important;
        font-size: 48px;
        font-weight: 500;
        margin-top: 0;
    }

    :global(.H4 p) {
        font-size: 20px;
        line-height: 24px;
    }

    :global(.H2 p) {
        color: $purple;
        font-size: 28px;
        font-weight: 500;
        line-height: 48px;
        margin: 32px 0 25px;
    }

    :global(.disclaimer p) {
        font-size: 12px !important;
        line-height: 18px !important;
    }

    :global(.Dr-hyper) {
        display: inline !important;
        font-size: 28px !important;
        line-height: 40px !important;
        color: #fff !important;
        background: $purple-dark;
        border-radius: 5px;
    }

    @media only screen and (max-width: $breakpoint-max) {
        :global(span) {
            font-size: 10px;
        }

        :global(.H1 p) {
            font-size: 30px !important;
            line-height: 40px !important;
            color: $purple-dark !important;
        }

        :global(.H2 p) {
            font-size: 20px;
            line-height: 36px;
            margin: 32px 0 25px;
        }

        :global(p) {
            font-size: 18px;
            line-height: 28px;
            margin-top: 0;
        }

        :global(.purple) {
            display: inline !important;
            color: #8347ad !important;
            font-size: 18px !important;
        }

        :global(.hyperlink) {
            font-size: 18px !important;
            padding: 0 5px;
        }
    }
}

.lpSectionContainer {
    background: $pink-light-2;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 50px 0;

    @media only screen and (min-width: $breakpoint-max) {
        background: $pink-light-2 url("https://images.ctfassets.net/8g0fievzqg8k/528gf3IjOb8ACYYxY3mrWm/3591604bee851b0610d978de173c40cc/flower3_PC.png") no-repeat top right;
        padding-bottom: 25px;
    }

    @media only screen and (max-width: $breakpoint-max) {
        flex-direction: column;
    }

    @media only screen and (min-width:1280px) and (max-width: 1600px) {
        background-position: 130% 0;
    }

    @media only screen and (min-width:1601px) {
        background-position: 108% 100%;
    }

    img {
        width: auto;
        margin: 10px 0;

        @media only screen and (max-width: $breakpoint-max) {
            min-height: 161px;
            width: 161px;
            margin: 10px;
        }
    }

    iframe {
        width: 478px;
        height: 478px;
        margin-top: 12px;

        @media only screen and (max-width: $breakpoint-max) {
            height: 335px;
            width: 335px;
        }
    }

    video {
        object-fit: cover;
        width: 478px;
        height: 478px;
        margin-top: 12px;

        @media only screen and (max-width: $breakpoint-max) {
            height: 335px;
            width: 335px;
        }
    }

    .imageSection {
        @media only screen and (max-width: $breakpoint-max) {
            display: flex;
            margin: auto;
        }

        div {
            @media only screen and (max-width: $breakpoint-max) {
                margin: 0 auto;
                width: 50%;
            }
        }
    }
}

.homeProductCardContainer {
    display: flex;
    max-width: 1180px;
    justify-content: space-evenly;
    margin: 24px 0;
    align-items: center;
}

.Homepage {

    .lazyLoadName{
        width: 100% !important;
        margin: auto;
        display: flex;
        height: 665px;
        @media (max-width: $breakpoint-max-md-ipad) {
            height: 220px;
        }

        iframe{
            @media (min-width: $breakpoint-dt-max) {
                max-width: 1180px !important;
                width:100% !important;
                margin: 0 auto !important;
            }
        }
    }

    iframe.homeYoutube {
        @media only screen and (max-width: 1279px) {
            margin: 0 auto;
            width:100%;
        }
        @media (max-width: 1279px) {
            width: 90%;
        }
    }

    .homeProductSection {
        background-color: $light-purple-bg;
        padding: 50px;
        width: 100%;
        text-align: center;

        h1 {
            text-align: left;
            font-size: 40px;
            color: $purple-darker;
            font-family: 'Noto Sans JP';
            font-weight: 500;
            margin-bottom: 40px;
            max-width: 1180px;
            margin: 0 auto 40px;

            @media only screen and (max-width: $breakpoint-max-md-ipad) {
                font-size: 18px;
            }
        }

        .Homepage-promo-banner {
            margin-bottom: 30px;
        }

        a.homeCompareCallout {
            background-color: $purple-darker;
            color: #fff;
            padding: 15px 31px;
            font-family: 'Noto Sans JP';
            font-weight: bold;
            font-size: 18px;

            @media only screen and (max-width: $breakpoint-max-md-ipad) {
                font-size: 1rem;
            }

            &::after {
                content: ">";
                font-size: 18px;
                color: #fff;
                padding-left: 22px;
                font-weight: 600;
            }
        }

        a.homeCompareCallout:hover {
            text-decoration: none;
        }

        .homeSecondaryBanner {
            max-width: 1180px;
            margin: 40px auto;
        }

        .homeProductCardContainer {
            max-width: 1180px;
            margin: auto;

            @media (max-width: $breakpoint-ipad-max) {
                flex-wrap: wrap;
            }

            .homeProductCard:last-child {
                margin-right: 0;
            }

            .homeProductCard {
                margin-right: 20px;
                background-color: transparent;
                border: none;
                .card-media{
                    max-width: 100%;
                }
                @media (max-width: $breakpoint-ipad-max) {
                    width: 50%;
                    margin-right: 0;
                }

                .card-body {
                    position: absolute;
                    bottom: 10px;
                    width: 100%;

                    @media (max-width: 450px) {
                        bottom: 0;
                        padding: 20px;
                    }
                }

                .homePrdouctCardBtn {
                    background-color: $purple-darker;
                    color: #fff;
                    padding: 5px 40px;
                    border-radius: 50px;
                    font-family: 'Noto Sans JP';
                    font-weight: 500;
                    font-size: 18px;

                    @media (max-width: 450px) {
                        padding: 5px 9px;
                        font-size: 12px;
                    }
                }

                .homePrdouctCardBtn:hover {
                    text-decoration: none;
                }
            }
        }
    }
}`;

export default styles;