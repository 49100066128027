import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Head from 'next/head';
import { classNameForGA, dataActionForGA } from '../../helpers/dataForGA';
import style from './ImageCarousel.scss';
import Image from '../CloudinaryImage/CloudinaryImage';
import useScreenSize from '../Hooks/useScreenSize';
import SlickDefaultStyle from '../../../styles/slick.scss';
import getScreenSize from '../ReusableComponents/useScreenSize';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';
import { getImageUrl } from '../../adapters/cloudinary.adapter';
import LazyLoad from 'react-lazyload';

const CustomArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div
            className={`${className} event_online_tool`}
            data-action-detail={className}
            onClick={onClick}
        />
    );
};

const ImageCarousel = ({ slidesCollection, name }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const resolution = useScreenSize();

    const carouselSettings = {
        arrows: true,
        className: 'banner-carousel',
        infinite: false,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 8000,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
        beforeChange: (_old, current) => setCurrentSlide(current),
        afterChange: (current) => setCurrentSlide(current),
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    dots: true,
                    lazyLoad: 'ondemand',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    pauseOnHover: false,
                    arrows: false,
                    appendDots: (dots) => (
                        <div
                            style={{
                                position: 'absolute',
                                width: '100%',
                                bottom: '15px',
                            }}
                        >
                            <ul style={{ listStyleType: 'none', display: 'flex', justifyContent: 'center', paddingLeft: '0' }}> {dots} </ul>
                        </div>
                    ),
                    customPaging: () => (
                        <div
                            style={{
                                width: '100px',
                                height: '10px',
                                margin: '0px 20px',
                                backgroundColor: 'white',
                            }}
                        />
                    ),
                },
            },
        ],
    };

    const productCarouselSettings = {
        infinite: true,
        speed: 500,
        className: 'product-lineup-carousel',
        autoplay: true,
        autoplaySpeed: 8000,
        slidesToShow: 3,
        pauseOnHover: false,
        slidesToScroll: 1,
        beforeChange: (_old, current) => setCurrentSlide(current),
        afterChange: (current) => setCurrentSlide(current),
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    dots: true,
                    lazyLoad: 'ondemand',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    pauseOnHover: false,
                    arrows: false,
                    appendDots: (dots) => (
                        <div
                            style={{
                                position: 'absolute',
                                width: '100%',
                                bottom: '15px',
                            }}
                        >
                            <ul style={{ listStyleType: 'none', display: 'flex', justifyContent: 'center', paddingLeft: '0' }}> {dots} </ul>
                        </div>
                    ),
                    customPaging: () => (
                        <div
                            style={{
                                width: '70px',
                                height: '7px',
                                margin: '0px 10px',
                                backgroundColor: 'blue',
                            }}
                        />
                    ),
                },
            },
        ],
    };
    const settings = name === 'Product Carousel- Top Page' ? productCarouselSettings : carouselSettings;

    const getLinkUrl = (link) => {
        switch (link?.__typename) {
        case 'ExternalLink':
            return link.url;
        case 'Page':
            return link.seoHead.url;
        default:
            return '';
        }
    };
    const { isDesktop,isMobile } = getScreenSize()
    // name !== 'Product Carousel- Top Page' ? console.log(slidesCollection?.items,'tt') : ''
    return (
        <>
            <Head>
            <meta name="viewport" content="width=device-width,initial-scale=1" />
            {(name == 'Product Carousel- Top Page') ? (
                <>
                    {
                       isDesktop && slidesCollection?.items?.map((item,i)=><link rel="preload" as="image" href={buildContentfullImageUrl(getImageUrl(item?.imageDt?.asset?.url, '1920'))} />) }
                    {  isMobile && slidesCollection?.items?.map((item,i)=>{if(i<3) {
                        return <link rel="preload" as="image" href={buildContentfullImageUrl(getImageUrl(item?.imageSp?.asset?.url, '480'))} />
                    } })}
                    
                </>
            )
                : 
                <>
                    {isMobile && slidesCollection?.items?.map((item,i)=>i<2 ? <link rel="preload" as="image" href={buildContentfullImageUrl(getImageUrl(item?.imageSp?.asset?.url, '480'))} /> : '')}
                    {isDesktop && slidesCollection?.items?.map((item,i)=>i<2 ? <link rel="preload" as="image" href={buildContentfullImageUrl(getImageUrl(item?.imageDt?.asset?.url, '1920'))} /> : '')}
                </>
            }
            </Head>
            <div className="popup-container">

                <div className="image-carousel">
                    {
                        name === 'Product Carousel- Top Page'
                            ? (
                                <Slider {...settings}>
                                    {slidesCollection?.items.map((item) => {
                                        const { link } = slidesCollection.items[currentSlide];
                                        const url = link ? getLinkUrl(link) : '';
                                        return (<a
                                            className={classNameForGA(getLinkUrl(item.link))}
                                            {...dataActionForGA(getLinkUrl(item.link))}
                                            href={url}
                                        >
                                            <LazyLoad offset={40}>
                                                <Image image={resolution?.isMobile ? item?.imageSp : item.imageDt} />
                                            </LazyLoad>
                                        </a>)
})}
                                </Slider>
                            )
                            : (
                                <Slider {...settings}>
                                    {slidesCollection?.items.map((item, i) => {
                                        const { link } = slidesCollection.items[currentSlide];
                                        const url = link ? getLinkUrl(link) : '';
                                        return url
                                            ? (
                                                <div key={i}>
                                                    <a
                                                        className={classNameForGA(url)}
                                                        {...dataActionForGA(url)}
                                                        href={url}
                                                    >
                                                        <LazyLoad offset={40}>
                                                            <Image image={resolution?.isMobile ? item.imageSp : item.imageDt} />
                                                        </LazyLoad>
                                                    </a>
                                                </div>
                                            )
                                            : (
                                                <div key={i}>
                                                    <LazyLoad offset={40}>
                                                        <Image image={resolution?.isMobile ? item.imageSp : item.imageDt} />
                                                    </LazyLoad>
                                                </div>
                                            );
                                    })}
                                </Slider>
                            )
                    }
                </div>
                <style jsx>{SlickDefaultStyle}</style>
                <style jsx>{style}</style>
            </div>
        </>
    );
};

ImageCarousel.propTypes = {
    name: PropTypes.string,
    slidesCollection: PropTypes.object,
};

CustomArrow.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
};

export default ImageCarousel;
